import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, Button, CircularProgress, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core'

const url = '/api/img'

const useStyles = makeStyles({
    image: {
        maxHeight: '100%',
        maxWidth: '100%',
    },
})

const addItems = async (state, setState, setError) => {
    try {
        const response = await fetchRetry(url, 1000, 3)
        const data = await response.json()
        if (response.status === 200) {
            setState([...state, ...data])
        } else {
            throw Error(data.message || 'An unknown error occurred while fetching images')
        }
    } catch (e) {
        setError(e)
    }
}

const wait = (delay) => {
    return new Promise((resolve) => setTimeout(resolve, delay))
}

const fetchRetry = (url, delay, tries, fetchOptions = {}) => {
    let triesLeft = 0
    const onError = (err) => {
        triesLeft = tries - 1
        if (!triesLeft) {
            throw err
        }
        return wait(delay).then(() => fetchRetry(url, delay, triesLeft, fetchOptions))
    }

    return fetch(url, fetchOptions).catch(onError)
}

const Base64Image = ({ b64, alt }) => {
    const src = `data:image/png;base64,${b64}`
    const styles = useStyles()
    return (
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
            <Box className={styles.image} display="block">
                <img className={styles.image} src={src} alt={alt}/>
            </Box>
        </Box>
    )
}

const ImageGrid = ({ images }) => {
    return (
        <Grid container>
            {images.map((it, index) => (
                <Grid item xs={4} key={index} height="20vh">
                    <Base64Image b64={it} alt={index}/>
                </Grid>
            ))}
        </Grid>
    )
}

const Loader = () => {
    return (
        <Box width="100%" height="100px" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress/>
        </Box>
    )
}

export const App = () => {
    const [state, setState] = useState([])
    const [error, setError] = useState()

    useEffect(() => {
        addItems(state, setState, setError)
    }, [])

    const next = () => addItems(state, setState, setError)
    const retry = () => {
        if (error) {
            setError(null)
            next()
        }
    }

    return (
        <Container>
            <InfiniteScroll
                next={next}
                hasMore={true}
                loader={error ? null : <Loader/>}
                dataLength={state.length}
                scrollThreshold={0.8}>
                <ImageGrid images={state}/>
            </InfiniteScroll>
            {error ? (
                <Container>
                    <Paper elevation={3}>
                        <Box display="flex" flexDirection="column" justifyContent="center">
                            <Typography width="fit-content" align='center'>
                                Error: {error.message}
                            </Typography>
                            <Button onClick={retry}>Retry</Button>
                        </Box>
                    </Paper>
                </Container>
            ) : null}
        </Container>
    )
}

export default App
